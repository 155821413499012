export default [
  {
    label: 'Toto588',
    code: 'Toto588',
    src: './images/home/lottovip.png',
    classshow: 'pages-icon-coming',
    route: '/nagaid',
    idrActive: 0,
    id: 32
  },
  {
    label: 'KissVip Lotto',
    code: '97lotto',
    src: './images/home/lottokissvip.png',
    classshow: 'pages-icon-hot',
    route: '/97lotto',
    idrActive: 0,
    id: 12
  },
  {
    src: './images/home/casinowebet.png',
    classshow: 'pages-icon-hot',
    // src: '/images/gameLogo/world-intertainment.png',
    code: 'WE',
    label: 'WE',
    route: 'we',
    idrActive: 0,
    id: 26
  },
  {
    src: './images/home/casinosexy.png',
    classshow: '',
    // src: '/images/gameLogo/casino-sexy.png',
    code: 'sexy_baccarat',
    label: 'Sexy Baccarat',
    route: 'awc/SEXYBCRT/LIVE',
    idrActive: 0,
    // game: ,
    id: 23
  },
  {
    label: 'King maker',
    code: 'sexy_baccarat',
    // src: 'images/gameLogo/games-kingmaker.png',
    src: './images/home/slotkingmaker.png',
    classshow: 'pages-icon-hot',
    route: 'awc/KINGMAKER/TABLE',
    idrActive: 0,
    id: 23
  },
  {
    label: 'Rich88',
    code: 'rich88',
    // src: 'images/gameLogo/16819162614882448910499172725626.png',
    src: './images/home/slotrich88.png',
    classshow: 'pages-icon-hot',
    route: '/rich88',
    idrActive: 0,
    id: 31
  },
  {
    src: './images/home/slotsg.png',
    classshow: 'pages-icon-hot',
    // src: '/images/gameLogo/slot-sg.png',
    code: 'sexy_baccarat',
    label: 'Spade Gaming',
    route: 'awc/SPADE/SLOT',
    id: 23
  },
  {
    src: './images/home/sportmuaystep2.png',
    classshow: 'pages-icon-hot',
    code: 'muaystep2',
    label: 'MUAY STEP 2',
    route: 'muaystep2',
    idrActive: 0,
    id: 29
  },
  {
    src: './images/home/sportm8.png',
    classshow: 'pages-icon-hot',
    code: 'm8',
    label: 'M8',
    route: '/m8',
    id: 9
  }
]
