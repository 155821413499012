import React, { useEffect, useState } from 'react'
import { Space, Table, Tag } from 'antd'
import moment from 'moment'
import { PropagateLoader } from 'react-spinners'
import { withStyles } from '@material-ui/core/styles'
import styles from '../histories/style'
import './style.css'
const columns = [
  {
    title: 'Date',
    dataIndex: 'created_at',
    key: 'created_at',
    width: 200,
    render: (text) => <span>{moment(text).format('DD-MM HH:hh')}</span>,
  },
  {
    title: 'Ref',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Trans',
    dataIndex: 'amount',
    key: 'amount',
    render: (text) => <span style={{color: Number(text || 0) < 0 ? 'red' : 'green'}}>{Number(text || 0) ? Number(text || 0).toFixed(2, 10) : 0.00}</span>,
  },
  {
    title: 'Balance',
    dataIndex: 'after',
    key: 'after',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (text) => {
      const data = text === 1 ? 'Success': text
      console.log(data)
      let color = 'green'
      if(data.toLowerCase() === 'pending') color = 'blue'
      else if(['rejected', 'reject'].includes(data.toLowerCase())) color = 'red'
      return <span style={{color}}>{text}</span>
    },
  },
  {
    title: '',
    key: 'action',
    render: () => (
      <Space size="middle">
        <img style={{width: 20}} src='images/icon/i.png' alt='' />
      </Space>
    ),
  },
];

const App = ({bankings, loading}) => {

  return (
    <>
    { loading ? (
        <div style={{ width: '100%', justifyContent: 'center', margin: 150, padding: "20px" }}>
          <PropagateLoader sizeUnit="px" size={20} color="#f50057" loading={loading}
          />
        </div>
      ) : <div>
      <Table style={{paddingBottom: 30}} size="small" columns={columns} dataSource={bankings} />
    </div>
    }
    </>
)}
export default withStyles(styles)(App)
